<template>
  <div>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col
        span="3"
        v-if="[1].includes(curDemandType)"
      >
        <RadioGroup
          v-model="dataSource"
          size="small"
          type="button"
          @on-change="handleChangeData"
        >
          <Radio
            v-for="item in dataSourceArray"
            :key="item.value"
            :label="item.value"
          >{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
      <i-col span="3">
        <Select
          v-model="assetId"
          size="small"
          placeholder="资产"
          @on-change="handleChangeAsset"
        >
         <Option :value="-1">全部资产</Option>
          <Option
            v-for="item in companyAssetArray"
            :key="'asset_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="query.stationId"
          size="small"
          placeholder="站点"
          :clearable="true"
          :disabled="assetId===-1"
        >
          <Option
            v-for="item in stationArray"
            :key="'station_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col
        span="2"
        v-show="curDemandType!==8"
      >
        <Select
          v-model="query.position"
          size="small"
          placeholder="位置"
          :clearable="true"
        >
          <Option
            v-for="item in positionArray"
            :key="'position_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col
        span="2"
        v-show="curDemandType!==8"
      >
        <Select
          v-model="query.devicemodel"
          size="small"
          :clearable="true"
          placeholder="设备类型"
        >
          <Option
            v-for="item in deviceTypeArray"
            :value="item.id"
            :key="'deviceType'+item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col>
      <i-col
        span="2"
        v-if="curDemandType!==8"
      >
        <i-select
          size="small"
          placeholder="设备状态"
          clearable
          v-model="query.enabled"
        >
          <i-option
            v-for="item in deviceStatusArray"
            :key="'dStatus_' + item.id"
            :value="item.id"
          >{{ item.name }}</i-option>
        </i-select>
      </i-col>
      <i-col span="3">
        <Input
          size="small"
          v-model="query.keyword"
          placeholder="资源编号、站点名称、画面名称"
        />
      </i-col>
      <i-col span="1">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          @click="handleSearch"
        >搜索</Button>
      </i-col>
      <i-col span="4">
        <Button
          v-if="[8,9].includes(curDemandType)"
          type="primary"
          size="small"
          class="m-r-5"
          :disabled="selectedResourceIdArray.length===0"
          @click="handleAddNewTask"
        >设置任务</Button>
        <Button
          v-if="curDemandType===1"
          type="success"
          size="small"
          class="m-r-5"
          :disabled="selectedResourceIdArray.length===0"
          @click="handleConfirmAdd"
        >确认添加</Button>
        <Button
          v-if="curDemandType!==1"
          type="success"
          size="small"
          @click="handleSubmitTask"
        >提交全部任务</Button>
      </i-col>
    </Row>
    <div>
      <Table
        stripe
        max-height="620"
        size="small"
        :data="tableData.list"
        :columns="tableColumns"
        :loading="dataLoading"
        @on-selection-change="handleSelection"
      ></Table>
      <Row :gutter="8">
        <i-col span="12">
          <div
            class="m-t-5"
            style="line-height:25px;"
          >当前已选中<span style="color:#ff9900;padding:0 3px">{{selectedResourceIdArray.length}}</span>条</div>
        </i-col>
        <i-col span="12">
          <div class="paging_style">
            <Page
              size="small"
              :total="tableData.totalRow"
              :page-size="query.pageSize"
              show-total
              show-elevator
              show-sizer
              :page-size-opts="pagesizeSetting"
              @on-page-size-change='changePageSize'
              :current="query.pageNumber"
              @on-change="changePage"
            ></Page>
          </div>
        </i-col>
      </Row>

    </div>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{fileName}}</p>
      <img-light-box ref="imgLightBox"></img-light-box>
    </Modal>
  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
import { companyMixin } from '@/assets/mixins/company'
import { commonMixins } from '@/assets/mixins/common'
import { addTaskMixins } from '../mixins/addTask'
import { formartMspTaskitemStatus } from '@/utils/tagStatus'
// import { getStationByAssetIds } from '@/api/product/station'
import { submit, addFillTaskitem } from '@/api/msp/demandV3'
import { closeTrouble } from '@/api/msp/trouble'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { getStatusList } from '@/api/rim/device'

import ImgLightBox from '@/components/common/ImgLightBox'
export default {
  mixins: [sysMixins, companyMixin, commonMixins, addTaskMixins],
  components: {
    ImgLightBox
  },
  data () {
    return {
      // dataSourceArray: [
      //   { value: 1, name: '设备列表' },
      //   { value: 2, name: '上报列表' }
      // ],
      dataSourceArray: [
        { value: 1, name: '在刊设备' },
        { value: 2, name: '所有设备' }
      ],
      companyId: this.$store.getters.token.userInfo.companyId,
      assetId: null,
      query: {
        // taskId: this.$store.state.task.curTaskId,
        assetId: '',
        stationId: null,
        position: null,
        resourcetypeId: null,
        devicemodel: null,
        keyword: '',
        enabled: 1,
        // removeNotFinishTask: true,
        pageSize: 15,
        pageNumber: 1
      },
      stationArray: [],
      positionArray: [],
      resourceTypeArray: [],
      deviceTypeArray: [], // 设备类型
      deviceStatusArray: [], // 设备状态
      pagesizeSetting: [15, 50, 100],
      tableColumns: [],
      dataLoading: false,
      demandTypeShowColumns: new Map([
        [5, ['code', 'stationName', 'devicemodelName', 'positionName', 'operate']], // 维修
        [8, ['selection', 'sName']], // 巡检
        [9, ['selection', 'code', 'stationName', 'devicemodelName', 'positionName']], // 维护
        [1, ['selection', 'code', 'stationName', 'devicemodelName', 'side', 'currentFile', 'actionDate', 'actionName', 'curEndDate', 'nextSchedule', 'enabledName']], // 自定义上刊(画面填充)
        ['1_2', ['selection', 'code', 'stationName', 'devicemodelName', 'enabledName']] // 自定义上刊(画面填充)-所有资源
        // ['5_2', ['deviceCode', 'stationName', 'devicemodelName', 'positionName', 'desc', 'attach', 'operate']], // 维修-上报资源显示列表
        // ['9_2', ['selection', 'deviceCode', 'stationName', 'devicemodelName', 'positionName', 'desc', 'attach', 'operate2']] // 维护-上报资源显示列表
      ]),
      visiblePreview: false,
      fileName: '',
      selectedTroubleIds: []
    }
  },
  computed: {
    tableData: {
      get () {
        const results = this.$store.state.task.tableData
        if (results.list) {
          results.list.forEach(item => {
            if (this.$store.state.task.selectedResourceIdArray.some(x => x === item.deviceId)) {
              item._checked = true
            }
          })
        }
        return results
      },
      set (val) {
        this.$store.commit('set_table_data', val)
      }
    },
    curDemandType () {
      return this.$store.state.task.curDemandType
    },
    selectedResourceIdArray: {
      get () {
        return this.$store.state.task.selectedResourceIdArray
      },
      set (val) {
        this.$store.commit('set_selected_resourceId_array', val)
      }
    },
    dataSource: {
      get () {
        return this.$store.state.task.dataSource
      },
      set (val) {
        this.$store.commit('set_data_source', val)
      }
    },
    tempSelectedResourceIds: { // 临时存储分页保留之前选中的deviceIds
      get () {
        return this.$store.state.task.tempSelectedItem
      },
      set (val) {
        this.$store.commit('set_temp_selected_item', val)
      }
    },
    selectedFaultIdArray: {
      get () {
        return this.$store.state.task.selectedFaultIdArray
      },
      set (val) {
        this.$store.commit('set_selected_faultid_array', val)
      }
    }
  },
  created () {
    this.setLinkTagArray({ key: 'AddDemandTask', value: '添加任务', actived: true })
    this.setActivedTagName('AddDemandTask')
    this.setLinkRouterMaps(['AddDemandTask', () => { this.handlePage() }])

    this.$store.commit('set_opreate_type', 1) // 新增操作标识设置

    this.getCompanyAssetArray()
    // this.initData()
  },
  destroyed () {
    this.selectedResourceIdArray = []
    this.tableData = {}
    this.dataSource = 1
    this.$store.commit('set_page_query')
    this.$store.commit('set_temp_selected_item', null)
  },
  methods: {
    /**
     * 动态生成Table列表
     */
    getTableColumns (key) {
      const tableColumnArray = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        resourceCode: { title: '资源编号', key: 'resourceCode', minWidth: 100, align: 'center' },
        code: { title: '编号', key: 'code', minWidth: 100, align: 'center' },
        sName: { title: '所属站台', key: 'name', minWidth: 100, align: 'center' },
        stationName: { title: '所属站台', key: 'stationName', minWidth: 100, align: 'center' },
        devicemodelName: { title: '设备类型', key: 'devicemodelName', minWidth: 100, align: 'center' },
        resourceTypeName: { title: '资源类型', key: 'resourcetypeName', minWidth: 100, align: 'center' },
        positionName: { title: '位置', key: 'positionName', minWidth: 80, align: 'center' },
        side: { title: '画面数', key: 'side', minWidth: 60, align: 'center' },
        enabledName: { title: '设备状态', key: 'enabledName', minWidth: 60, align: 'center' },
        actionDate: { title: '上画日期', key: 'actionDate', minWidth: 100, align: 'center' },
        currentFile: {
          title: '当前画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemFiles && params.row.taskitemFiles.length > 0) {
              return h('div', [
                h(
                  'img',
                  {
                    domProps: {
                      src: params.row.taskitemFiles[0].fileKey + '?x-oss-process=image/resize,w_64'
                    },
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.taskitemFiles.map(x => x.fileKey)
                        }
                        this.fileName = params.row.stationName
                        this.$refs.imgLightBox.init(postData)
                        this.visiblePreview = true
                      }
                    },
                    style: {
                      width: '64px',
                      cursor: 'pointer'
                    }
                  }
                )
              ])
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        picturesource: {
          title: '画面来源',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.picturesourceName),
              params.row.isReuseOldFile ? h('p', {
                style: { color: '#ff9900' }
              }, '使用已有画面') : null
            ])
          }
        },
        userName: {
          title: '巡检人员',
          align: 'center',
          minWidth: 60,
          render: (h, params) => {
            return h('span', params.row.taskitemLabors.userName)
          }
        },
        desc: {
          title: '问题描述',
          align: 'center',
          render: (h, params) => {
            if (params.row.category1Name) {
              return h('span', params.row.category1Name + '-' + params.row.category2Name)
            } else {
              return h('span', '-')
            }
          }
        },
        attach: {
          title: '上报画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.attachList && params.row.attachList.length > 0) {
              return h('div', [
                h(
                  'img',
                  {
                    domProps: {
                      src: params.row.attachList[0].fileKey + '?x-oss-process=image/resize,w_64'
                    },
                    on: {
                      click: () => {
                        const postData = {
                          startIndex: 0,
                          imgList: params.row.attachList.map(x => x.fileKey)
                        }
                        this.$refs.imgLightBox.init(postData)
                        this.visiblePreview = true
                      }
                    },
                    style: {
                      width: '64px',
                      cursor: 'pointer'
                    }
                  }
                )
              ])
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        actionName: {
          title: '画面名称',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.taskitemFiles && params.row.taskitemFiles.length ? Array.from(new Set(params.row.taskitemFiles.map(x => x.fileName))) : '')
          }
        },
        curEndDate: { title: '档期结束日期', minWidth: 100, align: 'center', key: 'currentScheduleEndDate' },
        nextSchedule: {
          title: '下个档期',
          minWidth: 130,
          align: 'center',
          render: (h, params) => {
            return h('span',
              GetCurrentSchedule(params.row.nextScheduleStartDate, params.row.nextScheduleEndDate, false)
            )
          }
        },
        status: {
          title: '状态',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return formartMspTaskitemStatus(h, params.row.status, params.row.statusName)
          }
        },
        remark: { title: '备注', key: 'remark', align: 'center', minWidth: 100, ellipsis: true, tooltip: true },
        operate: {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            var html = [
              h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.selectedResourceIdArray = [params.row.id]
                    // 对上报资源进行设置
                    if (this.dataSource === 2) {
                      this.selectedFaultIdArray = [params.row.id]
                    }
                    this.selectedResourceIdArray = [params.row.deviceId]
                    this.handleSetRepairTask()
                  }
                }
              }, '设置')
            ]
            if (this.dataSource === 2) {
              html.push(
                h('a', {
                  style: { color: '#ef4f4f' },
                  on: {
                    click: () => {
                      this.selectedTroubleIds.push(params.row.id)
                      this.handleColoseTrouble()
                    }
                  }
                }, '关闭')
              )
            }
            return h('div', html)
          }
        },
        operate2: {
          title: '操作',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            var html = []
            if (this.dataSource === 2) {
              html.push(
                h('a', {
                  style: { color: '#ef4f4f' },
                  on: {
                    click: () => {
                      this.selectedTroubleIds.push(params.row.id)
                      this.handleColoseTrouble()
                    }
                  }
                }, '关闭')
              )
            }
            return h('div', html)
          }
        }
      }

      const data = []
      var showColumns = this.demandTypeShowColumns.get(key)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.demandTypeShowColumns.get(5)
      }
      showColumns.forEach(col => data.push(tableColumnArray[col]))

      return data
    },
    initData () {
      this.tableColumns = this.getTableColumns(this.curDemandType)
      if (this.curDemandType === 1) {
        // 自定义上刊(画面填充)获取数据
        if (this.dataSource === 1) {
          // this.query.assetIds = JSON.stringify([this.query.assetId])
          this.getPublishingTaskitemData()
        } else {
          this.getAllDeviceData()
          this.tableColumns = this.getTableColumns(this.curDemandType + '_2')
        }
      } else if (this.curDemandType === 8) {
        this.getStationPageData()
      } else {
        // if (this.dataSource === 1) {
        //   this.getTableData()
        // } else {
        //   this.getTroubleData()
        //   this.tableColumns = this.getTableColumns(this.curDemandType + '_2')
        // }
      }
    },
    getTableData () {
      this.dataLoading = true
      this.query.devicemodel = null
      this.query.graph = null
      this.$store.dispatch('getDeviceData', this.query).finally(() => { this.dataLoading = false })
    },
    getTroubleData () {
      this.dataLoading = true
      this.$store.dispatch('getTroubleData', this.query).finally(() => { this.dataLoading = false })
    },
    getStationPageData () {
      this.dataLoading = true
      this.query.taskId = this.$store.state.task.curTaskId
      this.query.removeNotFinishTask = false
      this.$store.dispatch('getStationData', this.query).finally(() => { this.dataLoading = false })
    },
    getPublishingTaskitemData () {
      this.dataLoading = true
      this.query.taskId = this.$store.state.task.curTaskId
      this.query.removeNotFinishTask = false
      this.query.resourcetypeId = this.query.devicemodel
      this.$store.dispatch('getPublishingTaskitemData', this.query).finally(() => { this.dataLoading = false })
    },
    /**
     * 分页获取所有资源数据
     */
    getAllDeviceData () {
      this.dataLoading = true
      const { enabled, assetId, stationId, position, devicemodel, keyword, pageNumber, pageSize } = this.query
      this.$store.dispatch('getAllDeviceData', { enabled, assetId, stationId, position, devicemodel, keyword, pageNumber, pageSize }).finally(() => { this.dataLoading = false })
    },
    getDeviceStatusData () {
      getStatusList({ assetId: this.query.assetId }).then(res => {
        if (res && !res.errcode) {
          this.deviceStatusArray = res
        } else {
          this.deviceStatusArray = []
        }
      })
    },
    /**
     * 切换数据来源
     */
    handleChangeData () {
      this.query.pageNumber = 1
      this.query.stationId = null
      this.query.position = null
      this.query.resourcetypeId = null
      this.query.devicemodel = null
      this.query.keyword = ''
      this.query.enabled = 1
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds = []
      this.initData()
    },
    handleChangeAsset () {
      this.query.assetId = this.assetId === -1 ? undefined : this.assetId
      this.query.pageNumber = 1
      // this.$store.commit('set_selected_assetId', this.query.assetId)
      this.query.stationId = null
      this.query.position = null
      this.query.resourcetypeId = null
      this.query.devicemodel = null
      this.query.keyword = ''
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds = []
      this.getStationData()
      this.getPositionData()
      if (this.curDemandType !== 8) {
        this.getDeviceStatusData()
        this.getResourceTypeData()
      }

      this.initData()
    },
    handleSearch () {
      this.query.assetId = this.assetId === -1 ? undefined : this.assetId
      this.query.pageNumber = 1
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds = []
      this.initData()
    },
    changePageSize (pagesize) {
      this.query.pageNumber = 1
      this.query.pageSize = pagesize
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds = []
      this.initData()
    },
    changePage (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    handleSelection (selection) {
      let relateIds = []
      if (this.curDemandType === 8) { // 巡检
        relateIds = selection.map(x => x.id)
      } else if (this.curDemandType === 1) { // 自定义上刊(画面填充)
        if (this.dataSource === 1) {
          relateIds = selection.map(x => x.deviceId)
        } else {
          relateIds = selection.map(x => x.id)
        }
      } else {
        relateIds = selection.map(x => x.deviceId)
      }
      this.handelPageSelections(this.tempSelectedResourceIds, relateIds, this.query.pageNumber)
      // 最终选中的所有taskItemIds
      this.selectedResourceIdArray = []
      this.tempSelectedResourceIds.forEach(item => {
        this.selectedResourceIdArray = this.selectedResourceIdArray.concat(item.existSelections)
      })
    },
    handleAddNewTask () {
      if (this.curDemandType === 1) { // 自定义上刊(画面填充)
        this.$store.commit('set_left_component', 'SetFillFile')
      } else {
        this.$store.commit('set_left_component', 'SetMaintainTask')
      }
      this.$store.commit('set_show_left_block', true)
    },
    handleSetRepairTask () {
      this.$store.commit('set_left_component', 'SetRepairTask')
      this.$store.commit('set_show_left_block', true)
    },
    handleSubmitTask () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要提交已设置任务？',
        onOk: () => {
          submit({ taskId: this.query.taskId }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              // 处理切换到首页
              this.setActivedTagName('DemandIndex')
              this.setShowLeftBlock(false)
              this.setShowRightBlock(false)
              this.setShowMap(false)
              this.setBottomHeight('90%')
              this.setLeftComponent('')
              this.setRightComponent('')
              this.setBottomComponent('IndexTable')
            }
          })
        }
      })
    },
    /**
     * 关闭上报资源
     */
    handleColoseTrouble () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要关闭所选上报资源？',
        onOk: () => {
          closeTrouble({ troubleIds: JSON.stringify(this.selectedTroubleIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.initData()
            }
          })
        }
      })
    },
    handleConfirmAdd () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要添加所选资源？',
        onOk: () => {
          addFillTaskitem({ deviceIds: JSON.stringify(this.selectedResourceIdArray), taskId: this.query.taskId }).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '操作成功' })
              this.selectedResourceIdArray = []
              this.initData()
            }
          })
        }
      })
    },
    handlePage () {
      if (this.curDemandType === 1) {
        // 自定义上刊(画面填充)填充处理
        this.setShowLeftBlock(true)
        this.setLeftComponent('UninstallScreen')
      } else {
        this.setShowLeftBlock(false)
        this.setLeftComponent('')
      }

      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setRightComponent('')
      this.setBottomComponent('AddTask')
    }
  },
  watch: {
    companyAssetArray: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val && val.length) {
          this.assetId = val[0].id
          this.query.assetId = this.assetId === -1 ? undefined : this.assetId
          // this.$store.commit('set_selected_assetId', this.query.assetId)
          this.getStationData()
          this.getPositionData()
          if (this.curDemandType !== 8) {
            this.getResourceTypeData()
            this.getDeviceStatusData()
          }

          this.initData()
        }
      }
    }
  }
}
</script>
